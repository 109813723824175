<template>
      <article class="c-grid--services__item" 
      @mouseover="updateShowServideDescription(model.serviceDescription)">
        <router-link :to="model.linkUrl">
          <div class="card c-card c-card--service-box">
            <div class="card-image">
              <img v-bind:src=model.imgUrl />
              <div class="card-title color-text-black">
                <span class="card-sub-title color-text-black">{{ model.category }}</span><span class="card-option">{{
                  model.title }}</span>
              </div>
            </div>
          </div>
          <div class="card__item__hover">
            <span>Envíos puerta a puerta en Lima y provincias</span>
          </div>
        </router-link>
      </article>
</template>

<script>

export default {
  name: "AppCard",
  props: {
    model: {
      title: String,
      category: String,
      imgUrl: String,
      linkUrl: String,
      description: String,
      serviceDescription: String
    }
  },

  methods: {
    updateShowServideDescription(type) {
      
      this.$store.dispatch("SET_SHOW_SERVICE_DESCRIPTION", type);
    },
  },
  computed: {
  }
}
</script>>
<style scoped>
a {
  position: relative;
}
.card__item__hover {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px;
  visibility: hidden;
  position: absolute;
  bottom: 0px;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  border-radius: 14px;
  background-color: #989393;
  line-height: 1;
  color: white;
  font-size: 20px;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}
a:hover .card__item__hover {
  visibility: visible;
  opacity: 1;
}
</style>
