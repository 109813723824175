var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-content c-scrollbar pb-0 c-top-space c-content--spacing-top"},[_c('div',{staticClass:"c-grid c-grid--services"}),_c('app-input-select-ps',{attrs:{"label":"hola","value":"seleccione","options":[
      {
        id: 1,
        name: 'Punto Scharff 1 es super super super largo',
        district: 'Lima-Lima-Santiago de Surco',
        address: 'Av las gavitoas'
      },
      {
        id: 2,
        name: 'Punto Scharff 2',
        district: 'Lima-Lima-San Juan de Lurigancho',
        address: 'Casa lejos de casa'
      },
      {
        id: 3,
        name: 'Punto Scharff 3',
        district: 'Lima-Lima-San Miguel',
        address: 'Manco Segundo 225'
      },
      {
        id: 4,
        name: 'Punto Scharff 4',
        district: 'Lima-Lima-San Migueles',
        address: 'Manco Segundo 225sss'
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }