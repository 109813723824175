<template>
  <div class="sign-up-container">

    <div class="signup-global-container">
      <router-view name="signUpStep" />
    </div>

    <div class='img_container'>
      <div class='image_register'>
        <div class='text-5xl-bold img_text'>Contigo en cada paso de tu envío</div>
        <div class='img_illustration'>
          <img src="../assets/img/default/illustration-signUp.svg">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ScharffLogo from "../components/General/ScharffLogo.vue";

export default {
  name: "SignUp",
  title: "Registrar",
  components: {
    ScharffLogo,
  },
};
</script>

<style lang="scss">
.sign-up-container {
  display: grid;
  grid-template-columns: 1fr auto;
  min-height: 100vh;
  justify-items: center;
  .signup-global-container {
    margin:0;
    width: 100%;
    padding: 0 64px;
    max-width: 60rem;    
  }
}

@media (max-width: 1000px) {
  .sign-up-container { grid-template-columns: 100%;}
  .signup-global-container { padding: 0 20px !important; }
}
</style>
