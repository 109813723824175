<template>
  <div class="c-content c-scrollbar pb-0 c-top-space c-content--spacing-top">
    <div class="c-grid c-grid--services">
      <!-- <app-input-select-district id="programmed-step-2-originDistrict" text="Buscar distrito"
                @getDistrictId="setOriginDistrict" serviceType="2" ref="inputDistrict" @input="districtId = 0"
                placeholder="gola" :messageError='"ingrese un dato"' :isError="valida"></app-input-select-district> -->
    </div>

    <!-- <app-input-select :label="'XD'" :options="['go', 'python', 'rust', 'javascript']" :default="'go'" :isError="valida"
            class="select" @input="setInput" :messageError="'prueba'" />
        {{ value }}

        <br>
        <button v-on:click="cambia"> Cambia </button> -->

    <app-input-select-ps
      label="hola"
      value="seleccione"
      :options="[
        {
          id: 1,
          name: 'Punto Scharff 1 es super super super largo',
          district: 'Lima-Lima-Santiago de Surco',
          address: 'Av las gavitoas'
        },
        {
          id: 2,
          name: 'Punto Scharff 2',
          district: 'Lima-Lima-San Juan de Lurigancho',
          address: 'Casa lejos de casa'
        },
        {
          id: 3,
          name: 'Punto Scharff 3',
          district: 'Lima-Lima-San Miguel',
          address: 'Manco Segundo 225'
        },
        {
          id: 4,
          name: 'Punto Scharff 4',
          district: 'Lima-Lima-San Migueles',
          address: 'Manco Segundo 225sss'
        }
      ]"
    />

  </div>
</template>

<script>
import AppInputSelectDistrict from "../../components/form/app-input-select-district.vue";
import AppInputSelect from "../../components/form/app-input-select.vue";
import AppInputSelectPs from "../../components/form/app-input-select-ps.vue";

export default {
  name: "DemoInputs",
  title: "Lista de Ejemplos",
  components: {
    AppInputSelectDistrict,
    AppInputSelect,
    AppInputSelectPs
  },

  data() {
    return {
      value: "",
      valida: true
    };
  },
  props: {},
  methods: {
    setInput(id) {
      this.value = id;
    },
    cambia() {
      this.valida = !this.valida;
    }
  },
  computed: {}
};
</script>
>

<style scoped></style>
