<template>
  <div class="" style="width: 100%">
    <label class="custom-select-label">{{ label }}</label>
    <div class="input-select-group" :class="{ placeholder: !selected, 'input-focus': open }"   >
        <input class="input-primary" :placeholder="placeholder" v-model="value" @click="open = !open"> 
    </div>
    <div class="items c-scrollbar" style="z-index: 100; overflow-y: scroll" :class="{ selectHide: !open }">
      <div class="item" :class="{ 'item-select': selected == option }" v-for="(option, i) of options" :key="i" @click="setOption(option)">
        <p class="i__distrito">{{ option.district }}</p>
        <p class="i__name">{{ option.name }}</p>
        <p class="i__address">{{ option.address }}</p>
      </div>
    </div>
    <p class="message-error message-error-defualt" v-if="isError">
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AppInputSelectPs",
  props: {
    label: String,
    placeholder: String,
    isError: Boolean,
    messageError: {
      type: String,
      required: false
    },
    options: Array
  },
  data() {
    return {
      open: false,
      selected: null,
      value: null
    };
  },
  methods: {
    setOption(item) {
      this.selected = item;
      this.open = false;
      const _districts = item.district.split("-");
      const name = `${item.name  },${  _districts[2]  },${  _districts[1]}`;
      this.value = name;
      $emit("input", option.id);
    }
  },
  mounted() {}
};
</script>

<style scoped>
.input-focus {
  border: 2px solid #1a1a1a!important;
}
.input-select-group:hover {
  border: 1px solid #1a1a1a;
}
.placeholder {
  color: #737373;
}

.items .item {
  border-bottom: 1px solid #dedede;
}

.item-select {
  background: rgba(0, 0, 0, 0.15);
}

.i__distrito {
  padding-top: 12px;
  color: #737373;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.i__name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.i__address {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 13px;
  /* identical to box height, or 143% */

  color: #1a1a1a;
}

.items div {
  color: #1a1a1a;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.items div:hover {
  background: rgba(0, 0, 0, 0.05);
}

.items {
  height: 270px;
  color: #000000;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.selectHide {
  display: none;
}

.input-select-group {
  min-height: 48px;
  margin-top: 12px;
  /* padding-bottom: 12px; */
  /* padding-left: 16px; */
  border: 1px solid #737373;
  border-radius: 8px;
}

.input-primary,
.input-primary:focus {
  border-bottom: none;
}
.input-primary {
  margin-bottom: 0px!important;
}
.input-primary:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  padding-bottom: 0px!important;

}

.message-error {
  margin-left: 5px;
}

.message-error-defualt {
  color: #ff565d;
}

.custom-select-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #1a1a1a;
  /* margin: 30px 0 !important; */
}
</style>
