<template>
  <div class='bg-white'>
    <div>
      <div v-show="!isMobile">
        <div
          class='close-btn-modal'
          style="
            position: absolute; top: 20px; right: 30px;
            cursor: pointer; z-index: 9
          "
          @click='closeModal'
        >
          <Icon name='X' />
        </div>
        <div class="c-calculator__container__result">
          <div class="c-calculator__container__result__base pt-1">
            <div class="text text-2xl-bold">Nuevas tarifas</div>
          </div>
        </div>
      </div>
      <div v-show="isMobile" style="position: sticky; top: 0; z-index: 2;">
        <div>
          <div class="c-nav c-nav-relative" style="height:5rem;" ref="navBar">
            <div v-on:click="closeModal" class="button-back"></div>
            <div :style="tittleStyles" class="mobile_title_on_scroll">Nuevas tarifas</div>
          </div>
        </div>
      </div>
      <div v-show="isMobile" class="modal-mobile-full__title pb-4">
      <!-- style="position: sticky; top: -0.7rem; z-index: 2; background-color: transparent;" -->
        <div class="text">Nuevas tarifas</div>
      </div>
      <div style="position: sticky; top: 5rem; z-index: 9">
        <div class="c-calculator__container border-radius-above">
          <div
            class="c-section--content-tab-c c-section-title-tab mb-0 mobile-top-0"
          >
            <ul class="c-nav--tab-d-c tabs">
              <li class="c-tab-button tab" @mouseenter="handleMouseEnter('puntos')" @mouseleave="handleMouseLeave('puntos')">
                <a
                  href="javascript:void(0)"
                  v-bind:class="[type === 'puntos' || puntosHovered ? 'active' : 'noActive']"
                  @click="type = 'puntos'"
                >
                  <svg width="20" height="20" stroke-width="1.2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :color="type === 'puntos' || puntosHovered ? '#1A1A1A' : '#737373'">
                    <path d="M20 10c0 4.418-8 12-8 12s-8-7.582-8-12a8 8 0 1 1 16 0z" :stroke="type === 'puntos' || puntosHovered ? '#1A1A1A' : '#737373'"/>
                    <path d="M12 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" :fill="type === 'puntos' || puntosHovered ? '#1A1A1A' : '#737373'" :stroke="type === 'puntos' || puntosHovered ? '#1A1A1A' : '#737373'" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span class='text-m-regular'>Puntos Sharf</span>
                </a>
              </li>
              <li class="c-tab-button-n tab" @mouseenter="handleMouseEnter('programmed')" @mouseleave="handleMouseLeave('programmed')">
                <a
                  href="javascript:void(0)"
                  v-bind:class="[type === 'programmed' || programmedHovered ? 'active' : 'noActive']"
                  @click="type = 'programmed'"
                  >
                  <svg width="20" height="20" stroke-width="1.2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :color="type === 'programmed' || programmedHovered ? '#1A1A1A' : '#737373'">
                    <path d="M15 4V2m0 2v2m0-2h-4.5M3 10v9a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-9H3zm0 0V6a2 2 0 0 1 2-2h2m0-2v4m14 4V6a2 2 0 0 0-2-2h-.5" :stroke="type === 'programmed' || programmedHovered ? '#1A1A1A' : '#737373'" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span class='text-m-regular'>Puerta a puerta</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="background-color: white; margin: 0; padding: 0">
        <pm-quoter v-show="type === 'programmed'" />
        <ps-quoter v-show="type === 'puntos'" />
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import PmQuoter from "@/views/programmed/Quoter.vue";
import PsQuoter from "@/views/puntoScharff/Quoter.vue";
import Icon from '@/components/Icon.vue'

export default {
  name: "QuoterCmp",
  props: {
    scrollPixels: {
      type: Number
    },
  },
  components: {
    PmQuoter,
    PsQuoter,
    Icon,
  },
  data() {
    return {
      type: "puntos",
      showModalDetailQuoteProgrammed: false,
      isMobile: false,

      opacity: 0,
      blur: 0,
      startEffect: 0,
      endEffect: 90,
      blurMax: 2,
      blurMin: 0,

      puntosHovered: false,
      programmedHovered: false,
    };
  },
  methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleModalOpen() {},
    closeModal() {
      const modal = document.querySelector("#modalCalculatorScheduledDelivery");
      const instance = M.Modal.getInstance(modal);
      instance.close();
    },

    handleMouseEnter(type) {
      if(type === 'puntos') this.puntosHovered = true
      if(type === 'programmed') this.programmedHovered = true
    },
    handleMouseLeave(type) {
      if(type === 'puntos') this.puntosHovered = false
      if(type === 'programmed') this.programmedHovered = false
    }
  },
  mounted() {
    this.$nextTick(async () => {
      // this.initTabs();
      window.addEventListener("resize", this.updateIsMobile);
      this.updateIsMobile();
    })



    this.$watch('scrollPixels', pixeles => {
      let opacity = 0
      let blur = 0
      if (pixeles <= this.startEffect) {
          opacity = 0
          blur = this.blurMax
      } else if (pixeles >= this.endEffect) {
          opacity = 1
          blur = this.blurMin
      } else {
        opacity = (pixeles - this.startEffect) / (this.endEffect - this.startEffect)
        blur = 2 - opacity * 2
      }
      this.opacity = opacity
      this.blur = blur
    })
  },
  computed: {
    tittleStyles() {
      return {
        opacity: this.opacity,
        // filter: `blur(${this.blur * 2}px)`,
        'text-align': 'center'
        // "background-color": this.bgColor,
        // height: `${this.height}px`
      };
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
};
</script>

<style lang="scss">
.c-section-title-tab {
  position: relative !important;
  margin-bottom: 1.3rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
}

.border-radius-above {
  padding: 0em 0.5em;
  // padding-top: 1.3rem;

  @media screen and (max-width: 767px) {
    position: sticky;
    top: 5rem;
    z-index: 9;
    padding-top: 0;
  }
}

.modal-mobile-full__title {
  text-align: center;
  background-color: white;
  font-size: 24px;
  font-weight: 600;
  padding-top: 2rem;
}

.button-back {
  cursor: pointer !important;
  background: url("../../../assets/img/icon/icon-arrow-red.svg") no-repeat
    center center !important;
  content: " " !important;
  width: 40px !important;
  height: 40px !important;
  // position: static !important;
  top: 14% !important;
  padding: 0.5em 1em;
  position: absolute;
  z-index: 2;
}
.mobile_title_on_scroll {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1A1A1A;
}

.c-nav-relative {
  border-bottom: 1px solid #e0e0e0;
  box-shadow: none !important;
  position: relative !important;
  padding: 1em 1em;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tab {
  text-transform: none !important;
}

.tabs {
  background-color: transparent !important;
}

.tabs .tab a:focus, .tabs .tab a:focus.active{
  background-color: transparent !important;
}

.mobile-top-0 {
  @media screen and (max-width: 767px) {
    top: 0 !important;
  }
}




.fade-in-text {
  opacity: var(--text-opacity, 0);
  filter: blur(var(--text-blur, 10px));
  transition: opacity 0.5s ease-in, filter 0.5s ease-in;
}

.showText {
  opacity: 1;
  filter: blur(0);
}
</style>
