<template>
  <div>
    <label class="custom-select-label" for="">{{ label }}</label>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
      <div class="selected" :class="[isError ? 'custom-validate' : '', { open: open }]" @click="open = !open">
        <div v-if="this.selected === this.default" style="color: #737373;">
          {{ selected }}
        </div>
        <div v-else>
          {{ selected }}
        </div>
      </div>
      <div class="items" style="z-index: 100;" :class="{ selectHide: !open }">
        <div
          :class="{ 'item-select': selected == option }"
          v-for="(option, i) of options" :key="i"
          @click="
            selected = option;
            open = false;
            $emit('input', option);
          "
        >
          <div class="item_option">
            {{ option }}
          </div>
        </div>
      </div>

    </div>
    <p class="message-error message-error-defualt" v-if="isError">
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AppInputSelect",
  props: {
    label: String,
    isError: Boolean,
    messageError: String,
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
          ? this.options[0]
          : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped>
.message-error {
  margin-left: 5px;
}

.message-error-defualt {
  color: #ff565d;
}

.custom-select-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1A1A1A;
}

.selected:hover {
  border: 1px solid #1A1A1A !important;
}

.custom-validate {
  border: solid 1px #ff565d !important;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 48px;
  line-height: 48px;
}

.custom-select .selected {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #737373;
  color: #1A1A1A;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

/* .custom-select .selected:hover {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1A1A1A;
  color: #1A1A1A;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
} */

/* .custom-select .items .item-select {
  background: rgba(0, 0, 0, 0.2);
} */

.custom-select .selected.open {
  border: 2px solid #1A1A1A;
  border-radius: 6px 6px 6px 6px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #1A1A1A transparent transparent transparent;
}
.custom-select .items {
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 180px;
  overflow: overlay; /* para el scroll sea sobre las coasas */
  margin-top: 5px;
  line-height: 47px;
}

/* .custom-select .items div {
  color: #1A1A1A;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
} */
.custom-select .items > div:not(:last-child) {
  border-bottom: 1px solid #DEDEDE;
}

/* .custom-select .items div:hover {
  background-color: rgba(0, 0, 0, 0.1)
} */

.selectHide {
  display: none;
}


.item_option {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}
.item_option:hover { background: rgba(0, 0, 0, 0.05) }
.item_option:active { background: rgba(0, 0, 0, 0.15) }
</style>
