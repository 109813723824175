
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import M from "materialize-css";

export default {
  name: "AppInputSelectDistrict",
  components:{
    library,
    faHatWizard,
    FontAwesomeIcon
  },
  props: {
    id: String,
    text: String,
    serviceType: String,
    placeholder: String,
    messageError: String,
    isError: Boolean
  },
  data() {
    return {
      districId: null,
      districtVaule: "",
      lstDistricts: [],
      options: {
        data: { a: null, b: null },
        onAutocomplete: (e) => {
          this.districtVaule = e;
          this.getDistrictsId();
        },
      },
    };
  },
  mounted() {
    this.setOptions();
    this.$eventBus.$on("inputDistrictSetDistict", (district) => {
      this.setDistrict(district);
    });
  },
  methods: {
    setOptions() {
      const elemsAutocomplete = document.querySelectorAll(`#${this.id}`);
      const instances = M.Autocomplete.init(elemsAutocomplete, this.options);
      instances[0].open();
    },
    async getOptions() {
      if (this.districtVaule.length === 3) {
        const list = {};
        const district = this.districtVaule;
        if (this.serviceType === "2" || this.serviceType === "3") {
          // this.showLoading("Cargando...");
          this.$store
            .dispatch("GET_DISTRICTS", district)
            .then((response) => {
              this.lstDistricts = response;
              // eslint-disable-next-line no-restricted-syntax
              for (const value of this.lstDistricts) {
                list[value.text] = null;
              }
            })
            .then(() => {
              this.options.data = list;
              this.$swal.close();
              this.setOptions();
            });
        }
      }
    },
    async getOptionApi(id) {
      const list = {};
      this.$store
        .dispatch("GET_DISTRICTS", id)
        .then((response) => {
          this.lstDistricts = response;
          // eslint-disable-next-line no-restricted-syntax
          for (const value of this.lstDistricts) {
            list[value.text] = null;
          }
        })
        .then(() => {
          this.options.data = list;
          this.setOptions();
        });
    },
    getDistrictsId() {

      const district = this.lstDistricts.find((el) => this.isDistrict(el));

      if (district) {
        this.$emit("getDistrictId", { id: district.id, text: district.text });
        this.$emit("getProvinceId", district.provinceId);
        this.$emit("getDepartmentId", district.departmentId);
        this.isError = false;
      }else{
        this.districtVaule = null;
        this.isError = true;
      }
    },
    async setDistrict(district) {
      await this.getOptionApi(district.text);
      this.districtVaule = district.text;
      this.getDistrictsId();
    },
    isDistrict(element) {
      return element.text === this.districtVaule;
    },
    handleInput(e) {
      const { value } = e.target;
      this.districtVaule = value;
      this.getOptions();
      this.$emit("input", value);
    },
  },
  computed:{
    
  }
};
</script>

<template>
  <div>
    <div 
    class="input-field c-select"
    :class="[isError ? 'autocomplete-validate' : 'c-select-default']" >
    <!-- :class="{'autocomplete-validate': isError,'c-select': !isError, }" > -->
      <input
        :id="id"
        type="text"
        class="autocomplete autocomplete-primary"
        v-model="districtVaule"
        @input="handleInput"
        v-on:blur="getDistrictsId"
        autocomplete="off"
        :placeholder="placeholder"
        @focus="$emit('focus')"
      />
      <label class="default" :for="id">{{ text }}</label>
    </div>
    <p class="message-error message-error-defualt" v-if="isError">
      {{ messageError }}
    </p>
  </div>

  
</template>



<style>

.dropdown-content li:hover, .dropdown-content li.active {
  background-color: rgba(0, 0, 0, 0.1)!important;
}
.dropdown-content{
  z-index: 999999999 !important;
  left:0!important;
  width:100%!important;
}

.autocomplete-validate{
  border:solid 1px #ff565d!important;
  margin-top: 40px !important;
}
.message-error{
  margin-left: 5px;
}
.message-error-defualt{
  color: #ff565d;
}
.error-none{
  display: none;
}
.c-select-default{
  margin-top: 40px !important;
  border:solid 1px #737373!important;
}
.c-select-default:hover{
  border:solid 1px #1A1A1A!important;
}

.c-select-default:has(>input:focus){
  border:solid 2px #1A1A1A!important;
}

.autocomplete-content {
  top: 44.719px !important;
  max-height: 140px;
}
.message-error{
  margin-left: 5px;
  color: #E50000;
  font-weight: 400;
  font-size: 14px;
}
.default {
  transform: translateX(-12px) translateY(-30px) scale(0.9) !important;
  font-size: 14px !important;
}
</style>
