<template>
    <div class="c-content c-scrollbar pb-0 c-top-space c-content--spacing-top">
        <div class="c-grid c-grid--services">
            <div class="c-grid--services__container">
                <app-card v-bind:key="card.id" 
                :model="card" 
                v-for="card in cards"></app-card>
            </div>
        </div>
    </div>
</template>

<script>
import AppCard from "../components/card/app-card.vue"

export default {
    name: "DemoIndex",
    title: "Lista de Ejemplos",
    components: {
        AppCard
    },
    
    data() {
        return {
            cards: [
                {
                    id: 1,
                    title: "Inputs",
                    category: "Forms",
                    imgUrl: require('@/assets/img/service-programmed.svg'),
                    linkUrl: "/inputs",
                    description: "esta es una prueba",
                    serviceDescription: "programmed"
                }
            ]
        }
    },
    props: {

    },
    methods: {

    },
    computed: {

    }
}
</script>>

<style scoped></style>