<template>
  <div id='modalCompleteData' class='modal-complete-data modal'>
    <div class='main-container-complete-data'>
      <div class='titles-group'>
        <p class='text-4xl-bold'>¡Bienvenido/a!</p>
        <p class='text-m-regular' style='color: #737373'>Termina de completar tus datos. Nos ayudará a mejorar tu experiencia.</p>
      </div>
      <div class='inputs-group'>
        <InputText
          label='Nombre(s)'
          :maxLength='150'
          placeholder='Ingresa tu(s) nombre(s)'
          v-model='name'
          missingErrorMessage='Ingresa tu(s) nombre(s).'
          :showEmptyInputError='tryToRegister && !name'
        />
        <InputText
          label='Apellidos'
          :maxLength='150'
          placeholder='Ingresa tus apellidos'
          v-model='lastName'
          missingErrorMessage='Ingresa tus apellidos.'
          :showEmptyInputError='tryToRegister && !lastName'
        />
        <InputText
          label='Número de celular'
          autocomplete='new-password'
          placeholder='Ingresa un número de celular'
          v-model='phone'
          missingErrorMessage='Ingresa un número de celular.'
          :showEmptyInputError='tryToRegister && !phone'
        />
        <InputText
          :disabled='!!originalUserData.email'
          email
          label='Ingresa tu correo electrónico'
          autocomplete='email'
          :maxLength='150'
          placeholder='Ingresa tu correo electrónico'
          v-model='email'
          missingErrorMessage='Este campo es obligatorio.'
          :showEmptyInputError='tryToRegister && !email'
        />
      </div>
      <CustomCheckbox
        checkboxId='account-for-bussines'
        v-model='personType'
        label='Usaré la cuenta para mi negocio'
      />
      <CustomSelect
        v-if='personType'
        label='Cantidad de envíos al mes'
        placeholder='Selecciona un rango'
        :optionList='frequenciesList'
        v-model='frequency'
        errorMessage='Selecciona la cantidad de envíos que realizas al mes.'
        :showEmptyInputError='tryToRegister && !frequency'
        :isOptional="personType"
      />
      <ButtonDefinitive
        label='Guardar'
        :isLoading='isLoading'
        @click='processForm'
      />
    </div>
  </div>
</template>

<script>
import InputText from '../../components/Form/InputText.vue'
import CustomCheckbox from '../../components/Form/CustomCheckbox.vue'
import CustomSelect from '../../components/Form/CustomSelect.vue'
import ButtonDefinitive from '../../components/Button/ButtonDefinitive.vue'
import ErrorMessage from '../../components/ErrorMessage.vue'

export default {
  components: {
    CustomCheckbox,
    CustomSelect,
    ButtonDefinitive,
    InputText,
    ErrorMessage,
  },
  data() {
    return {
      originalUserData : {},
      name: '',
      lastName: '',
      phone: '',
      email: '',
      personType: true,
      frequency: '',

      frequenciesList: [
        { id: 'Carbon', label: '1 a 10 envíos' },
        { id: 'Bronce', label: '11 a 25 envíos' },
        { id: 'Silver', label: '26 a 50 envíos' },
        { id: 'Gold', label: '51 a más envíos' }
      ],
      tryToRegister: false,
      isLoading: false,
    }
  },
  methods: {
    processForm() {

      const user = this.$store.getters.USER
      const isAfterRegistrationAnalysisStartDate  = user.isAfterRegistrationAnalysisStartDate

      this.trackEvent('click_complete_user_data_save_btn', 'register')
      this.tryToRegister = true

      if (!this.name) {
        if(isAfterRegistrationAnalysisStartDate){
          this.trackEvent('complete_user_data_failed_incorrect_name_new_client', 'register')
        } else{
          this.trackEvent('complete_user_data_failed_incorrect_name', 'register')
        }

        return
      }

      if (!this.lastName){ 
        if(isAfterRegistrationAnalysisStartDate){
          this.trackEvent('complete_user_data_failed_incorrect_lastName_new_client', 'register')
        } else{
          this.trackEvent('complete_user_data_failed_incorrect_lastName', 'register')
        }
        
        return      
      }

      if (!this.phone){ 
        if(isAfterRegistrationAnalysisStartDate){
          this.trackEvent('complete_user_data_failed_incorrect_phone_new_client', 'register')
        } else{
          this.trackEvent('complete_user_data_failed_incorrect_phone', 'register')
        }

        return      
      }

      if (this.personType && !this.frequency){ 
        if(isAfterRegistrationAnalysisStartDate){
          this.trackEvent('complete_user_data_failed_incorrect_frequency_new_client', 'register')
        } else{
          this.trackEvent('complete_user_data_failed_incorrect_frequency', 'register')
        }

        return      
      }

      this.sendRegisterData()
    },
    async sendRegisterData() {

      const payload = {
        name: this.name,
        lastName: this.lastName,
        phoneNumber: this.phone,
        frequency: this.frequency,
        isEntrepreneur: this.personType,
        email: this.email
      }

      const user = this.$store.getters.USER
      const isAfterRegistrationAnalysisStartDate  = user.isAfterRegistrationAnalysisStartDate

      try {
        this.showLoading()
        const dataUpdated = await this.$store.dispatch('EDIT_USER_PROFILE', payload)
        this.$store.commit('UPDATE_USER', {
          phoneNumber: payload.phoneNumber,
          lastName: payload.lastName,
          fullName: dataUpdated.fullName,
          name: payload.name,
          email: payload.email
        })
        this.showOk('Datos actualizados')

        if(isAfterRegistrationAnalysisStartDate){
          this.trackEvent('complete_user_data_new_client_success', 'register')
        } else{
          this.trackEvent('complete_user_data_success', 'register')
        }
      } catch (e) {
        this.showError(e || 'Error al actualizar los datos.', 'Error', () => {
          this.openModal()

          if(isAfterRegistrationAnalysisStartDate){
          this.trackEvent('complete_user_data_new_client_failed', 'register')
          } else{
            this.trackEvent('complete_user_data_failed', 'register')
          }
          
        })
      } finally {
        this.closeModal()
      }


    },
    recoveryUserData() {
      const user = JSON.parse(localStorage.getItem('vuex'))?.userModule?.user
      if(!user) return
      if(!this.authenticated) return

      const { name, firstLastName, celular, email } = user

      if (name && firstLastName && celular) return

      this.openModal()

      this.name = name
      this.lastName = firstLastName
      this.phone = celular
      this.email = email
      this.originalUserData = user
    },
    openModal() {
      const modal = document.querySelector('#modalCompleteData')
      const instance = M.Modal.init(modal, { dismissible: false })
      if (instance) instance.open()
    },
    closeModal() {
      const modal = document.querySelector('#modalCompleteData')
      const instance = M.Modal.getInstance(modal)
      instance.close()
    },
  },
  computed: {
    authenticated() {
      return this.$store.getters.AUTHENTICATED;
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.recoveryUserData()
    })
  },
}
</script>

<style lang='scss' scoped>
.modal-complete-data {
  background-color: #ffffff;
  width: 384px !important;
  max-width: 100vw;
  top: 0 !important;
  bottom: 0;
  border-radius: 24px;
  padding: 32px;
  height: 528px !important;
  max-height: 100vh !important;
  overflow-y: scroll;

  .main-container-complete-data {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .titles-group {
      display: flex; flex-direction: column; gap: 8px;
    }
    .inputs-group {
      display: flex; flex-direction: column; gap: 16px;
    }
  }

  &::-webkit-scrollbar { width: 15px }
  &::-webkit-scrollbar-thumb {
    background: rgba(26, 26, 26, 0.5);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 15px;
  }
}

@media (max-width: 600px) {
  .modal-complete-data {
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0;
  }
}
</style>