<template>
  <div class='c-template--col-2__container'>
    <section>
      <div class='c-height-fluid c-content-vertical-align'>
        <div class='c-content' style='display: flex; justify-content: center'>
          <div style='max-width: 408px; display: flex; flex-direction: column; align-items: center'>
            <scharff-logo style='margin-top: 32px' />
            <div>
              <div class='c-form c-form--sign-in' style='display: flex; flex-direction: column; align-items: center; width: 100%'>
                <div class='text-4xl-bold' style='padding: 48px 0'>Iniciar sesión</div>

                <LoginForm formId='user-login' />

                <!-- Separator -->
                <div style='width: 100%; height: 68px; padding-top: 24px; padding-bottom: 24px; justify-content: center; align-items: center; display: inline-flex'>
                  <div style='flex: 1 1 0; height: 1px; background: #EBEBEB; border-radius: 360px'></div>
                  <div style='padding-left: 8px; padding-right: 8px; flex-direction: column; justify-content: center; align-items: center; gap: 8px; display: inline-flex'>
                    <div style='justify-content: center; align-items: center; gap: 8px; display: inline-flex'>
                      <div style='color: #737373; font-size: 14px; font-weight: 400; line-height: 20px; word-wrap: break-word'>o</div>
                    </div>
                  </div>
                  <div style='flex: 1 1 0; height: 1px; background: #EBEBEB; border-radius: 360px'></div>
                </div>

                <div style='width: 100%; display: flex; flex-direction: column; gap: 16px; padding-bottom: 48px'>
                  <!-- Google -->
                  <GoogleButton />
                  <!-- Apple -->
                  <AppleButton />
                  <!-- Facebook -->
                  <ButtonDefinitive
                    variant='ghost' label='Continuar con Facebook'
                    @click='logInWithFacebook'
                  >
                    <template v-slot:icon><img src='~@/assets/img/icon/icon-facebook_v2.svg'></template>
                  </ButtonDefinitive>

                  <p class='text-s-regular' style='text-align: center'>
                    Al seleccionar “Continuar con Google”, “Continuar con Apple" o “Continuar con Facebook” aceptas nuestros
                    <a href='https://www.holascharff.com/terminos-y-condiciones' class='c-link' target='_blank'>Términos y condiciones</a> y
                    <a href='https://www.holascharff.com/politicas-de-privacidad' class='c-link' target='_blank'>Políticas de privacidad</a>.
                  </p>
                  <div class='text-m-regular' style='text-align: center'>
                    ¿No tienes una cuenta?
                    <router-link to='/signUp' class='c-link'>Regístrate</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class='img_container'>
      <div class='image_register'>
        <div class='text-5xl-bold img_text'>Contigo en cada paso de tu envío</div>
        <div class='img_illustration' style='right: 40px'>
          <img src="../assets/img/default/illustration-logIn.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../components/Login/LoginForm.vue';
import ScharffLogo from '../components/General/ScharffLogo_2.vue';
import GoogleButton from '../components/Login/GoogleButton.vue';
import AppleButton from '../components/Login/AppleButton.vue';
import ButtonDefinitive from '../components/Button/ButtonDefinitive.vue'

export default {
  name: 'SignIn',
  title: 'Iniciar Sesión',
  components: {
    LoginForm,
    ScharffLogo,
    GoogleButton,
    AppleButton,
    ButtonDefinitive,
  },
  mounted() {
    this.$nextTick(async () => {
      await this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
      await this.initFacebook();
      this.closeSidebar();
    });
  },
  methods: {
    async logInWithFacebook() {
      const $this = this;
      $this.showLoading();
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const { accessToken } = response.authResponse;
            $this.$store
              .dispatch('FB_LOGIN', {accessToken, queryString: ''})
              .then((success) => {
                if (success.status === 200) {
                  const result = success.data.result;
                  $this.$store.dispatch('USER_SET_USER', result);

                  if(result.isNewClient){
                    this.trackEvent('login_with_facebook_after_register_success', 'login')
                  } else {
                    this.trackEvent('login_with_facebook_success', 'login')
                  }
                  
                  window.location.href = process.env.VUE_APP_WEBURL;
                } else {
                  $this.showError(success.data.message);
                  this.trackEvent('login_with_facebook_failed', 'login')
                }
              })
              .catch((error) => {
                const msg = error?.data?.message || 'Ocurrio un error.';
                $this.showError(msg);
                this.trackEvent('login_with_facebook_failed', 'login')
              });
          } else {
            $this.showError('Inicio de Sesion con Facebook Cancelado.');
            this.trackEvent('login_with_facebook_failed', 'login')
          }
        },
        { scope: 'email' }
      );
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_KEY,
          cookie: true,
          xfbml: true,
          version: 'v6.0',
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
};
</script>

<style lang='scss'>
.c-template--col-2__container {
  display: grid;
  grid-template-columns: 1fr auto;

}
.img_container {
  height: 100vh;
  width: 440px;
  max-width: 440px;
  position: relative;

  .image_register {
    position: fixed;
    right: 0;
    top: 0;
    background: #FFEFEF;
    height: 100vh;
    width: 440px;
    max-width: 440px;

    .img_text {
      color: #68002B; width: 257px;
      position: absolute; top: 40px; left: 40px;
    }
    .img_illustration {
      position: absolute; bottom: 40px; right: 10px;
    }
  }
}
@media (max-width: 1000px) {
  .img_container { display: none }
}
</style>
