<template>
  <div id="modalProductMarketFitSurvey" class="modal-product-market-fit modal">
    <div v-if="!isSurveyCompleted">

      <!-- <LottieAnimation
        path="./green-check.json"
      /> -->
      <div class="survey-header">
        <span class="survey-title">¡Ayúdanos a mejorar!</span>
        <span class="close-button" @click="closeModal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.757 17.243 12 12m5.243-5.243L12 12m0 0L6.757 6.757M12 12l5.243 5.243" stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </span>
      </div>
      <div class="survey-body">
        <div class="question-and-answer">
          <span class="survey-question">
            {{ `¿Qué tan decepcionado/a estarías si nuestro servicio ${serviceType} dejara de existir?` }}
          </span>
          <div style="margin-left: -15px !important;">
            <CustomRadioButton
              radioId="opcion1"
              inputValue="Muy decepcionado/a"
              v-model="q1"
              label="Muy decepcionado/a"
            />
            <CustomRadioButton
              radioId="opcion2"
              inputValue="Algo decepcionado/a"
              v-model="q1"
              label="Algo decepcionado/a"
            />
            <CustomRadioButton
              radioId="opcion3"
              inputValue="Nada decepcionado/a"
              v-model="q1"
              label="Nada decepcionado/a"
            />
          </div>
        </div>
        <div class="question-and-answer">
          <span class="survey-question">
            {{ `¿Qué tipo de persona crees que se beneficia más con nuestro servicio ${serviceType}?` }}
          </span>
          <InputText
            isTextArea
            v-model.trim="q2"
            :maxLength="150"
            placeholder="Ingresa tu respuesta"
            missingErrorMessage="Por favor, ingresa tu respuesta."
            :showEmptyInputError="tryToSendResponse && !q2"
          />
        </div>
        <div class="question-and-answer">
          <span class="survey-question">
            {{ `¿Cuál es el principal beneficio que tú recibes de nuestro servicio ${serviceType}?` }}
          </span>
          <InputText
            isTextArea
            v-model.trim="q3"
            :maxLength="150"
            placeholder="Ingresa tu respuesta"
            missingErrorMessage="Por favor, ingresa tu respuesta."
            :showEmptyInputError="tryToSendResponse && !q3"
          />
        </div>
        <div class="question-and-answer">
          <span class="survey-question">
            {{ `¿Cómo podemos mejorar nuestro servicio ${serviceType}?` }}
          </span>
          <InputText
            isTextArea
            v-model.trim="q4"
            :maxLength="150"
            placeholder="Ingresa tu respuesta"
            missingErrorMessage="Por favor, ingresa tu respuesta."
            :showEmptyInputError="tryToSendResponse && !q4"
          />
        </div>
        <div class="question-and-answer">
          <span class="survey-question">
            <!-- ¿Qué servicios o características adicionales te gustaría que ofreciera Scharff para facilitar tus envíos de paquetes? -->
            ¿Qué otros servicios te gustaría que Sharf tuviera para tus envíos?
            <!-- {{ `¿Cómo podemos mejorar nuestro servicio ${serviceType}?` }} -->
          </span>
          <InputText
            isTextArea
            v-model.trim="q5"
            :maxLength="150"
            placeholder="Ingresa tu respuesta"
            missingErrorMessage="Por favor, ingresa tu respuesta."
            :showEmptyInputError="tryToSendResponse && !q4"
          />
        </div>
        <div>
          <ButtonDefinitive
            label="Enviar respuestas"
            @click="handleSendResponse"
          />
          <ErrorMessage message="Tienes preguntas sin responder." v-if="tryToSendResponse && thereAreEmptyValues" />
        </div>
      </div>
    
    </div>
    <div v-else class="survey-completed">
      <div class="check-animation">
        <LottieAnimation
          :loop="false"
          :speed="2"
          path="assets/green-check.json"
        />
      </div>
      <span class="check-message">Gracias por tus respuestas</span>
    </div>
  </div>
</template>

<script>
import CustomRadioButton from '../../components/Form/CustomRadioButton.vue'
import InputText from '../../components/Form/InputText.vue'
import ButtonDefinitive from '../../components/Button/ButtonDefinitive.vue'
import ErrorMessage from '../../components/ErrorMessage.vue'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"
import { sendSurveyResponses } from '../../services/survey.js'

export default {
  components: {
    CustomRadioButton,
    InputText,
    ButtonDefinitive,
    ErrorMessage,
    LottieAnimation
  },
  props: {
    serviceType: {
      type: String,
      default: 'Puntos Sharf' // || Programados
    },
  },
  data() {
    return {
      tryToSendResponse: false,
      q1: 'Muy decepcionado/a',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      isSurveyCompleted: false
    }
  },
  methods: {
    handleSendResponse() {
      this.tryToSendResponse = true
      if(this.thereAreEmptyValues) return
      this.isSurveyCompleted = true

      const dataUser = localStorage.getItem('vuex')
      const dataUserParsed = JSON.parse(dataUser)

      const d = new Date();
      d.setUTCHours(d.getUTCHours() - 5)

      const data = {
        usuarioId: dataUserParsed.userModule.user.id,
        pregunta1: this.q1,
        pregunta2: this.q2,
        pregunta3: this.q3,
        pregunta4: this.q4,
        pregunta5: this.q5,
        fechaDeCreacion: d.toISOString(),
        tipoDeServicio: this.serviceType
      }

      sendSurveyResponses(data)
      .then(resp => {
        console.log(resp)
      })

      setTimeout(() => {
        this.closeModal()
        this.clearData()
      }, 2000)
    },
    closeModal() {
      // this.tryToSendResponse = true
      // if(this.thereAreEmptyValues) return
      const modal = document.querySelector('#modalProductMarketFitSurvey')
      const instance = M.Modal.getInstance(modal)
      instance.close()
    },
    clearData() {
      this.tryToSendResponse = false
      this.q1 = 1
      this.q2 = ''
      this.q3 = ''
      this.q4 = ''
      this.isSurveyCompleted = false
    },
  },
  computed: {
    thereAreEmptyValues() {
      const { q1, q2, q3, q4 } = this
      return (!q1 || !q2 || !q3 || !q4)
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-product-market-fit {
  background-color: #ffffff;
  width: 400px !important;
  max-width: 100vw;
  top: 0 !important;
  bottom: 0;
  border-radius: 24px;
}
.survey-header {
  position: sticky;
  top: 0;
  height: 55px;

  background-color: #fefefe;
  color: #1A1A1A;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  box-shadow: 0px -4px 16px 0px rgba(26, 26, 26, 0.16)
}
.survey-title {
  padding: 16px 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.close-button {
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 15px 24px;
}
.survey-body {
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow-y: scroll;
  max-height: 80vh;
  padding: 48px 32px;


  .question-and-answer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .survey-question {
      color: #1A1A1A;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &::-webkit-scrollbar { width: 15px; }
  &::-webkit-scrollbar-thumb {
    background: rgba(26, 26, 26, 0.5);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 15px;
  }
}

.survey-completed{
  height: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .check-animation {
    width: 48px;
    height: 48px;
  }
  .check-message {
    color: #1A1A1A;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
@media (max-width: 600px) {
  .modal-product-market-fit {
    width: 100% !important;
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
    border-radius: 24px 24px 0 0;
  }
  .survey-body {
    overflow-y: scroll;
    height: 80vh;
  }
}

</style>