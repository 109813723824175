<template>
  <div class="card-info animate__animated" :class="isExpanded ? 'expanded' : ''">
    <div class="textaasdasd">
      <span class="card-info__text">Costo de envío:</span>
      <span class="card-info__text card-info__text--bold">S/ {{ text.toFixed(2) }}</span>
    </div>
  </div>
</template>
<script>
import 'animate.css';

export default {
  props: {
    text: Number,
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style>
.card-info {
  display: none;

  width: 100%;
  height: 72px;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #f5f5f5;
  text-align: center;
  border-radius: 16px;
}

.card-info.expanded {
  display: flex;
  animation: fadeIn;
  animation-duration: .3s;
}


.textaasdasd {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.card-info__text {
  text-align: right;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card-info__text--bold {
  text-align: left;
  font-weight: bold;
}
</style>
