<template>
  <div class="app-tracking-container">
    <div v-if="isMobile || showTrackingSearch">
      <TrackingSearch @search="search" />
    </div>
    <div v-if="(isMobile || !showTrackingSearch) && guide">
      <TrackingResultHeader
        :guide="guide"
        @back="showTrackingSearch = true"
        :showBack="!isMobile"
      />
    </div>
    <div v-if="(isMobile || !showTrackingSearch) && guide">
      <TrackingDetail :guide="guide" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TrackingSearch from "../components/Tracking/TrackingSearch.vue";
import TrackingResultHeader from "../components/Tracking/TrackingResultHeader.vue";
import TrackingDetail from "../components/Tracking/TrackingDetail.vue";
import { getGuideByNumber } from "../services/guias";
import Guide from "../domain/models/guide";
import GuideActivity from "../domain/models/guideActivity";

export default Vue.extend({
  name: "TrackingView",
  components: { TrackingSearch, TrackingResultHeader, TrackingDetail },
  data() {
    return {
      guide: null,
      showTrackingSearch: true,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    search(numberGuide) {
      this.showLoading("Buscando...");
      getGuideByNumber(numberGuide)
        .then((response) => {
          this.$swal.close();
          if (response) {
            if (response.status === 200) {
              const { data } = response;
              if (data.Tracking.ListaTrackingIncidencia.length === 0) {
                this.showError("La guía ingresada no fue encontrada");
                this.guide = null;
                return;
              }
              const tracking = data.Tracking;
              const detalles = tracking.ListaTrackingIncidencia.map(
                (item) =>
                  new GuideActivity(
                    item.Incidencia,
                    item.Hora,
                    item.Descripcion,
                    "success"
                  )
              );
              this.guide = new Guide(
                numberGuide,
                tracking.Remitente,
                tracking.Consignatario,
                tracking.Destino,
                detalles.reverse()
              );
              this.showTrackingSearch = false;
            }
          }
        })
        .catch((error) => {
          this.$swal.close();
          this.showError("Ocurrió un error al intentar buscar la guía");
          console.error(error);
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.app-tracking-container {
  background-color: #f5f5f5;
  height: 100vh;
  overflow-y: auto;
}
</style>

<style lang="scss">
input {
  padding: 1px 20px !important;
  -webkit-box-sizing: border-box !important; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box !important; /* Firefox, other Gecko */
  box-sizing: border-box !important; /* Opera/IE 8+ */
  &:focus {
    border-bottom: solid 1px !important;
    box-shadow: none !important;
  }
}
</style>
