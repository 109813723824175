<template>
  <div>
    <div class="row">
      <div class="col s12 d-flex justify-content-center">
        <div class="" style="font-size: 24px; font-weight: 700;">{{ text }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col s12 d-flex justify-content-center">
        <router-link
          class="c-button c-button--primary c-button--mobile-small waves-effect d-block"
          :to="buttonLink"
          >{{ buttonText }}</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    buttonText: String,
    buttonLink: String,
  },
};
</script>
<style lang="scss">

</style>
