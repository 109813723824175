<template>
  <div>
    <div
      id="modalCalculatorScheduledDelivery"
      class="modal modal-mobile-full c-modal c-modal--calculator p-0"
      tabindex="0" ref="modal" @scroll="handleScroll"
    >
      <div class="p-0">
        <!-- <div class="c-modal--calculator__close modal-close"></div> -->
        <quoter :scrollPixels="scrollPixels" />
      </div>
    </div>
  </div>
</template>
<script>
import Quoter from "@/views/components/general/app-quoter.vue";

export default {
  data() {
    return {
      scrollPixels: 0
    }
  },
  components: {
    Quoter,
  },
  methods: {
    handleScroll() {
      this.scrollPixels = this.$refs.modal.scrollTop
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-mobile-full {
  width: 615px;
  overflow-y: hidden;

  @media screen and (max-width: 768px) {
    margin: 0;
    top: 0;
    max-height: 100% !important;
    height: 100% !important;
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
  }
}


</style>
